/*
 * Provides a way to edit image description,
 * If the image is deleted, all references (xrefs) are deleted with it on the API side
 */
(function () {
    angular
        .module('Plania')
        .controller('EditImageController', ['$scope', 'Repository', 'params', 'config', '$modalInstance', 'TranslationService', '$localStorage', controller]);

    function controller($scope, repository, params, config, $modalInstance, translationService, $localStorage) {
        $scope.model = angular.copy(params.image);
        $scope.viewOnly = params.viewOnly;
        $scope.entityType = params.entityType;
        $scope.imageXEntity = {};
        $scope.imageApiUrl = config.baseUrlApi + repository.apiData.image.url;
		$scope.deleteAccess = params.deleteAccess;
		$scope.editAccess = $scope.hasEditAccess('Image');
        $scope.isRunningAction = false;

        var supportedAttachmentTypes = [
            "PurchaseOrder",
            "Request",
            "WorkOrder",
        ];

        if ($localStorage.generalOptions.IsBBY) {
            supportedAttachmentTypes.push("Condition");
            supportedAttachmentTypes.push("Deviation");
        }

        $scope.canMarkAsAttachment = $localStorage.generalOptions.CanSetDocumentAsAttachment && supportedAttachmentTypes.includes($scope.entityType);

        $scope.editMode = false;
        $scope.imageChanged = false;

        $scope.imageSrc = $scope.imageApiUrl + $scope.model.Guid + '?' + $scope.model.timestamp;
        var cropper;

        $scope.toggleEdit = function (val) {
            if (val && val === "abort") {
                $scope.editMode = false;
                cropper.destroy();
            } else if (val) {
                $scope.editMode = val;
                onLoadFunction();
            } else {
                $scope.editMode = val;
                $scope.imageChanged = true;
                onEditEnd();
            }
        };

        var onEditEnd = function () {
            if ($scope.imageChanged) {
                $scope.imageSrc = "";
                var updateImageSource = new Promise(function (resolve, reject) {
                    cropper.getCroppedCanvas({
                        imageSmoothingEnabled: true,
                        imageSmoothingQuality: 'low',
                    }).toBlob(function (blob) {
                        var reader = new FileReader();
                        reader.onload = function (e) {
                            try {
                                $scope.imageSrc = e.target.result;
                                document.getElementById("imageCropperTarget").src = e.target.result;
                                resolve();
                            } catch (error) {
                                repository.growl(error);
                            }
                        };
                        reader.readAsDataURL(blob);

                    }, "image/jpeg");
                });
                Promise.allSettled([updateImageSource]).then(clearCropper);
            } else {
                cropper.destroy();
            }
        };

        var clearCropper = function () {
            cropper.destroy();
        };

        var onLoadFunction = function () {
            var image = document.getElementById('imageCropperTarget');
            cropper = new Cropper(image, {
                viewMode: 2,
                autoCrop: false,
            });
        };

        $scope.onIsDefaultChange = function (state) {
            if ($scope.isRunningAction || !$scope.hasEditAccess('ImageXEntity')) return;

            var urlParams = 'guidImage=' + $scope.model.Guid + '&guidEntity=' + params.guidEntity + '&state=' + state;
            $scope.isRunningAction = true;
            repository.runAction(repository.apiData.imageXEntity.endpoint.toggleIsDefault, null, urlParams, false).then(function (result) {
                if (params.onChange && typeof (params.onChange) === "function")
                    params.onChange($scope.model);

                $scope.isRunningAction = false;
            }, function (error) {
                $scope.isRunningAction = false;
                $scope.model.IsStandard = false;
                repository.growl(error, 'danger');
            });
        };

        $scope.onIsAttachmentChange = function (state) {
            if (!$scope.canMarkAsAttachment) return;

            if ($scope.isRunningAction || !$scope.hasEditAccess('ImageXEntity')) return;

            var urlParams = 'guidImage=' + $scope.model.Guid + '&guidEntity=' + params.guidEntity + '&state=' + state;
            $scope.isRunningAction = true;
            repository.runAction(repository.apiData.imageXEntity.endpoint.toggleIsAttachment, null, urlParams, false).then(function (result) {
                if (params.onChange && typeof (params.onChange) === "function")
                    params.onChange($scope.model);
                $scope.isRunningAction = false;
            }, function (error) {
                $scope.isRunningAction = false;
                repository.growl(error, 'danger');
            });
        };

        $scope.editImage = function () {
            var imageViewModel = {
                ImageSrc: null,
                Description: $scope.model.Description,
            };

            if ($scope.imageSrc.substr(0, $scope.imageSrc.indexOf("?")) !== ($scope.imageApiUrl + $scope.model.Guid))
                imageViewModel.ImageSrc = $scope.imageSrc.substr($scope.imageSrc.indexOf('base64,') + 'base64,'.length);

            repository.updateSingle(repository.apiData.image.url + "updateFullImage/", imageViewModel, "guid=" + $scope.model.Guid).then(function () {
                repository.growl('Bilde ble lagret', 'success');
                var result = $scope.model;
                result.timestamp = new Date().toISOString();
                $modalInstance.close(result);
            }, function (error) {
                if (error.Message)
                    repository.growl('Kunne ikke lagre bilde - ' + error.Message, 'danger');
                else
                    repository.growl('Kunne ikke lagre bilde - ' + error, 'danger');
            });
        };

        $scope.rotateImageClockWise = function () {
            cropper.rotate(90);
            $scope.imageChanged = true;
        };

        $scope.rotateImageCounterClockWise = function () {
            cropper.rotate(-90);
            $scope.imageChanged = true;
        };

        $scope.reset = function () {
            cropper.reset();
            cropper.replace($scope.imageApiUrl + $scope.model.Guid);
            $scope.imageSrc = $scope.imageApiUrl + $scope.model.Guid;
            document.getElementById("imageCropperTarget").src = $scope.imageApiUrl + $scope.model.Guid;
        };

        $scope.deleteImage = function () {
            swal({
                title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
                text: translationService.translate('web-swal-image-message', "Bildet vil bli permanent fjernet!"),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f44336",
                confirmButtonText: translationService.translate('web-swal-image-button-confirm', 'Ja, fjern bildet'),
                cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
                closeOnConfirm: false,
                showLoaderOnConfirm: true
            }, function () {
                window.onkeydown = null;
                window.onfocus = null;

                repository.deleteSingle(repository.apiData.image.url, $scope.model.Guid).then(function (result) {
                    swal(translationService.translate('web-swal-image-success', 'Bildet ble fjernet!'), result, "success");
                    $modalInstance.close('Deleted');

                }, function (error) {
                    if (error.Message)
                        repository.growl('Kunne ikke slette bilde - ' + error.Message, 'danger');
                    else
                        repository.growl('Kunne ikke slette bilde - ' + error, 'danger');
                });
            });
        };
    }
})();
