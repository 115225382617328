(function () {
	angular.module('Plania').controller('PersonController', ['$scope', 'Repository', '$stateParams', '$filter', 'ngTableParams', '$modal', 'authService', 'TranslationService', 'ListService', '$rootScope', controller]);

	function controller($scope, repository, $stateParams, $filter, ngTableParams, $modal, authService, translationService, listService, $rootScope) {
		$scope.update = $scope.navigation.current.name === 'person.edit';
		$scope.isBuildingCollapsed = true;
		$scope.restrictEdit = !authService.hasEditAccess('person');
		$scope.model = { Guid: $stateParams.guid };
		$scope.activateAutocomplete = false;
		$scope.viewMode = $scope.update ? true : false;
		$scope.reloadDocumentTable = false;
		$scope.reloadWorkOrderXResourceTable = false;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
		};

		var updateAccess = function () {
			if ($scope.update) {
				var checkOtherDo = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !authService.hasEditAccess(repository.commonService.prefix.Person, checkOtherDo);
				$scope.restrictDelete = !authService.hasDeleteAccess(repository.commonService.prefix.Person, checkOtherDo);
			} else {
				$scope.restrictEdit = !authService.hasCreateAccess(repository.commonService.prefix.Person);
			}
		};

		updateAccess();

		var setProperties = function () {
			var person = $scope.model;

			if (person.Department)
				$scope.Department = $scope.getEntityCaption(person.Department);

			if (person.Organization)
				$scope.Organization = $scope.getEntityCaption(person.Organization);
			if (person.OrganizationUnit)
				$scope.OrganizationUnit = $scope.getEntityCaption(person.OrganizationUnit);

			if (person.OrganizationSection)
				$scope.OrganizationSection = $scope.getEntityCaption(person.OrganizationSection);

			if (person.PersonRole)
				$scope.PersonRole = $scope.getEntityCaption(person.PersonRole);

			if (person.ResourceGroup)
				$scope.ResourceGroup = $scope.getEntityCaption(person.ResourceGroup);

			if (person.Area) {
				$scope.Area = $scope.getEntityCaption(person.Area);
			}
		};

		if ($scope.update) {
			var columns = ["Department.Id", "Organization.Id", "Organization.Description", "OrganizationUnit.Id", "OrganizationUnit.Description", "OrganizationSection.Id", "OrganizationSection.Description", "PersonRole.Id", "ResourceGroup.Id", 'Area.Id', 'Area.Description'];
			repository.getMainModel(repository.apiData.person.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					var person = response.Data;
					$scope.model = person;
					$scope.enableWebNavigation = $rootScope.userInfo.enableWebNavigation;
					updateAccess();
					$scope.reloadDocumentTable = true;

					setProperties();
					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				},
				function (error) {
					repository.growl(error, 'danger');
				});
		} else {

			$scope.activateAutocomplete = true;
			$scope.model.Hours = 40;

			var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
			if (webMenuParams && webMenuParams.Prefill) {
				webMenuParams.Prefill.forEach(function (prefill) {
					if (typeof (prefill.Value) === 'object') {
						$scope[prefill.Property] = prefill.Value.Label;
						$scope.model['Guid' + prefill.Property] = prefill.Value.Guid;
					} else {
						$scope.model[prefill.Property] = prefill.Value;
					}
				});
			}
		}

		$scope.getPropertyValue = function (row, column) {
			return listService.GetPropertyValue(row, column);
		};

		$scope.updatePerson = function (destination) {
			var success = function (result) {
				repository.growl($scope.update ? "Person har blitt oppdatert" : 'Person har blitt lagret', 'success');
				var guid = $scope.update ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.update)
				repository.updateSingle(repository.apiData.person.url, $scope.model).then(success).catch(error);
			else
				repository.createSingle(repository.apiData.person.url, $scope.model).then(success).catch(error);

		};

		$scope.removePerson = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-person-message', 'Personen vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-person-button-confirm', 'Ja, fjern personen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.person.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-person-success', 'Personen ble fjernet!'), result, "success");
						$scope.goBack('person.list', { menuGuid: $scope.navigation.params.menuGuid });
					}, function (error) {
						swal({
							title: "Kunne ikke slette personen!",
							text: error.Message + "<br/><br/> Vil du deaktivere personen?",
							type: "error",
							html: true,
							showCancelButton: true,
							confirmButtonText: "Deaktiver",
							cancelButtonText: "Avbryt",
							closeOnConfirm: true,
							closeOnCancel: true
						}, function (isConfirm) {
							window.onkeydown = null;
							window.onfocus = null;
							if (isConfirm) {
								$scope.model.EmployeeNr = '#' + $scope.model.EmployeeNr;
								$scope.model.IsDeactivated = true;
								$scope.updatePerson('person.list');
							}
						});
					});
			});
		};

		$scope.onOrganizationSelect = function (organization) {

			if (!organization) {
				$scope.model.GuidOrganization = "";
				return;
			}

			if ($scope.model.GuidOrganization !== organization.Guid) {
				$scope.model.GuidOrganizationUnit = "";
				$scope.model.GuidOrganizationSection = "";
				delete $scope.model.OrganizationUnit;
				delete $scope.model.OrganizationSection;
			}

			$scope.model.GuidOrganization = organization.Guid;

		};

		$scope.onOrganizationUnitSelect = function (organizationUnit) {

			if (!organizationUnit) {
				$scope.model.GuidOrganizationUnit = "";
				return;
			}
			if ($scope.model.GuidOrganizationUnit !== organizationUnit.Guid) {
				$scope.model.GuidOrganization = organizationUnit.GuidOrganization;
				$scope.model.GuidOrganizationSection = "";
				delete $scope.model.OrganizationSection;
			}
			$scope.model.GuidOrganizationUnit = organizationUnit.Guid;


		};

		$scope.onOrganizationSectionSelect = function (organizationSection) {

			if (!organizationSection) {
				$scope.model.GuidOrganizationSection = "";
				return;
			}

			if ($scope.model.GuidOrganizationSection !== organizationSection.Guid) {
				$scope.model.GuidOrganizationUnit = organizationSection.GuidOrganizationUnit;
				$scope.model.GuidOrganization = organizationSection.OrganizationUnit.GuidOrganization;

			}
			$scope.model.GuidOrganizationSection = organizationSection.Guid;

		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};

			switch (filterName) {
				case "organization":
					return filter;

				case "organizationUnit":

					if ($scope.model.GuidOrganization) {
						filter.PropertyFilter = [{ Property: 'GuidOrganization', Operator: '=', Value: $scope.model.GuidOrganization }];
					}

					return filter;
				case "organizationSection":
					if ($scope.model.GuidOrganizationUnit) {
						filter.PropertyFilter = [{ Property: 'GuidOrganizationUnit', Operator: '=', Value: $scope.model.GuidOrganizationUnit }];
					}
					return filter;


			}
		};

		var getMenuLink = function (url, text, icon) {
			var htmlText = '<a class="dropdown-item" tabindex= "-1" href="' + url + '" >';
			// Show icon, else draw a random icon to keep same padding on left.
			if (icon)
				htmlText += '<i class="fas ' + icon + ' fa-hc-fw fa-hc-lg"></i> ';
			else
				htmlText += '<i style="visibility:hidden;" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ';
			htmlText += text + '</a>';

			return {
				html: htmlText,
				isHref: true
			};
		};

		$scope.buildingPersonInfo = new function () {
			var me = this;
			this.isLoading = false;

			this.columns = [
				{ Position: 1, Title: translationService.translate('web-person-buildingXPerson-building-id', 'ByggNr'), Property: 'Building.Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-person-buildingXPerson-building-description', 'Bygg beskrivelse'), Property: 'Building.Description', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-person-buildingXPerson-buildingcategory-description', 'Byggkategori'), Property: 'Building.BuildingCategory.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuildingCategory', AutocompleteService: 'buildingCategory' },
				{ Position: 4, Title: translationService.translate('web-person-buildingXPerson-building-address', 'Adresse'), Property: 'Building.Address', PropertyType: 'string' },
				{ Position: 5, Title: translationService.translate('web-person-buildingXPerson-building-postalcode', 'Postnummer'), Property: 'Building.PostalCode', PropertyType: 'string' },
				{ Position: 6, Title: translationService.translate('web-person-buildingXPerson-building-postalarea', 'Poststed'), Property: 'Building.PostalArea', PropertyType: 'string' },
				{ Position: 7, Title: translationService.translate('web-person-buildingXPerson-costcenter-id', 'Koststed'), Property: 'Building.CostCenter.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidCostCenter', AutocompleteService: 'costCenter' },
				{ Position: 8, Title: translationService.translate('web-person-buildingXPerson-personrole-id', 'Rolle'), Property: 'PersonRole.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidPersonRole', AutocompleteService: 'personRole' },
			];

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { 'Building.Id': "asc" },
					filter: { PropertyFilter: [{ Property: "GuidPerson", Operator: "=", Value: $scope.model.Guid }] }
				}, {
					total: 0,
					filterDelay: 10,
					counts: [10, 20, 50],
					getData: function ($defer, params) {
						var columns = [];
						me.columns.forEach(function (col) {
							columns.push(col.Property);
						});

						me.isLoading = true;

						repository.GetPaginated(repository.apiData.buildingPerson.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns)).then(function (result) {
							me.isLoading = false;
							me.table.settings().total = result.TotalCount;
							me.table.settings().filterDelay = 500;
							$defer.resolve(result.List);
						}, function (error) {
							me.isLoading = false;
							repository.growl(error, 'danger');
						});
					}
				});
			};

			this.add = function () {
				$modal.open({
					templateUrl: 'app/buildingPerson/views/createBuildingPerson.html',
					controller: 'BuildingPersonController',
					resolve: {
						params: function () {
							return {
								GuidPerson: $scope.model.Guid,
								GuidPersonRole: $scope.model.GuidPersonRole,
								personRole: $scope.PersonRole,
								personCaption: $scope.model.FirstName + ' ' + $scope.model.LastName,
								selectedEntityGuids: _.map(me.table.data, function (row) {
									return { GuidBuilding: row.GuidBuilding, GuidPerson: row.GuidPerson, GuidPersonRole: row.GuidPersonRole };
								}),
								isModal: true
							};
						}
					}
				}).result.then(function () {
					me.table.reload();
				}, function () {
					//cancel
				});
			};

			this.edit = function (item) {
				$modal.open({
					templateUrl: 'app/buildingPerson/views/editBuildingPerson.html',
					controller: 'BuildingPersonController',
					resolve: {
						params: function () {
							return {
								modalParam: {
									isUpdate: true,
								},
								guid: item.Guid,
								isModal: true
							};
						}
					}
				}).result.then(function () {
					me.table.reload();
				}, function () {
					//cancel
				});
			};

			this.remove = function (item) {
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-person-building-message', 'Denne persones blir koblet bort fra bygningen!'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-person-building-button-confirm', 'Ja, fjern knytningen!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					repository.deleteSingle(repository.apiData.buildingPerson.url, item.Guid).then(function (result) {
						swal(translationService.translate('web-swal-person-building-success', 'Knytning ble fjernet!'), result, "success");
						me.table.reload();
					});
				});
			};

			this.contextMenuOptions = function (item) {
				var options = [];
				if ($scope.hasReadAccess('Building'))
					options.push(getMenuLink($scope.navigation.href('building.edit', { guid: item.GuidBuilding }), translationService.translate('web-person-buildingXPerson-viewBuilding', 'Gå til bygg')));

				if (me.canEdit(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href=""><i style="visibility:hidden;" class="fas fa-trash fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-person-buildingXPerson-edit', 'Rediger') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							$scope.buildingPersonInfo.edit(item);
						}
					});
				}

				if (me.canDelete(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href=""><i style="visibility:hidden;" class="fas fa-trash fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-person-buildingXPerson-delete', 'Slett knytning') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							$scope.buildingPersonInfo.remove(item);
						}
					});
				}
				return options;
			};

			this.canCreate = function () {
				return $scope.hasCreateAccess('BuildingXPerson');
			};

			this.canEdit = function (item) {
				return item &&
					$scope.hasEditAccess('BuildingXPerson');
			};

			this.canDelete = function (item) {
				return item &&
					$scope.hasDeleteAccess('BuildingXPerson');
			};
			this.initTable();
		}();

		$scope.doorKeyXCustomerColumns = [
			{ Position: 1, Title: translationService.translate('web-doorKey-id'), Property: 'DoorKey.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-doorKey-description'), Property: 'DoorKey.Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-doorKeyXUser-quantity'), Property: 'Quantity', PropertyType: 'number' },
			{ Position: 4, Title: translationService.translate('web-doorKey-creationDate'), Property: 'CreationDate', PropertyType: 'date' },
		];
		$scope.workOrderXResourceGroupColumns = [
			{ Position: 1, Title: translationService.translate('web-resourceGroup-id'), Property: 'ResourceGroup.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-resourceGroup-description'), Property: 'ResourceGroup.Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-workOrderXResourceGroup-estimatedCost'), Property: 'EstimatedCost', PropertyType: 'double' },
			{ Position: 4, Title: translationService.translate('web-workOrderXResourceGroup-estimatedTime'), Property: 'EstimatedTime', PropertyType: 'number' },
			{ Position: 5, Title: translationService.translate('web-workOrderXResourceGroup-realCost'), Property: 'RealCost', PropertyType: 'double' },
			{ Position: 6, Title: translationService.translate('web-workOrderXResourceGroup-realTime'), Property: 'RealTime', PropertyType: 'number' },
			{ Position: 7, Title: translationService.translate('web-workOrderXResourceGroup-updatedDate'), Property: 'UpdatedDate', PropertyType: 'date' },
		];


		$scope.onPostCodeSelect = function (postalInfo) {
			if (!postalInfo) {
				$scope.model.PostalArea = '';
				$scope.model.PostalCode = '';
			} else {
				$scope.model.PostalCode = postalInfo.ZipCode;
				$scope.model.PostalArea = postalInfo.PostalArea;
				$scope.postalInfo = postalInfo;
			}
		};

		$scope.onClickDoorKeyXCustomer = function (entity, event) {
			event.preventDefault();
			event.stopPropagation();
			$scope.navigation.go('doorKey.edit', { guid: entity.GuidDoorKey });
		};

		$scope.onClickWorkOrderXResourceGroup = function (resource, event) {
			if (event){
				event.preventDefault();
				event.stopPropagation();
			}
			$modal.open({
				templateUrl: 'app/workOrder/views/editWorkOrderXResourceGroupModal.html',
				controller: 'WorkOrderXResourceGroupController',
				resolve: {
					params: function () {
						return {
							GuidWorkOrderXResourceGroup: resource.Guid
						};
					}
				}
			}).result.then(function (cost) {
				$scope.reloadWorkOrderXResourceTable = !$scope.reloadWorkOrderXResourceTable;
			});
		};
		$scope.workOrderXResourceGroupContextMenuOptions = function(item){
			var options = [];

			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-trash fa-hc-fw fa-hc-lg"></i> ' +
					translationService.translate('web-person-workOrderXResource-edit', 'Rediger') + '</a >',
				click: function () {
					$scope.onClickWorkOrderXResourceGroup(item);
				}
			});

			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-trash fa-hc-fw fa-hc-lg"></i> ' +
					translationService.translate('web-person-workOrderXResource-delete', 'Fjern') + '</a >',
				click: function () {
					swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-swal-resource-message', 'Ressursen vil bli permanent fjernet!'),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-resource-button-confirm', 'Ja, fjern ressursen!'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false,
						showLoaderOnConfirm: true
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;
						repository.deleteSingle(repository.apiData.workOrderXResourceGroup.url, item.Guid)
							.then(function (result) {
								swal(translationService.translate('web-swal-resource-success', 'Ressursen ble fjernet!'), result, "success");
								repository.growl(translationService.translate('web-workorder-resoursgroup-removed', 'Ressurs er fjernet fra arbeidsordre'), 'success');
								$scope.reloadWorkOrderXResourceTable = !$scope.reloadWorkOrderXResourceTable;
							});
					});
				}
			});

			return options;
		};

		$scope.openMail = function () {
			window.location.href = 'mailto:' + $scope.model.Email;
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
