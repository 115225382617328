(function () {
    angular.module('Plania').controller('SelectIconModalController', ['$scope', '$modalInstance', "IconService", controller]);

    function controller($scope, $modalInstance, iconService) {

        $scope.activeTab = {
            "objects": true,
            "symbols": false,
            "activities": false,
            "alphanumeric": false
        };


        $scope.selectTab = function (action) {
            for (var key in $scope.activeTab) {
                if ($scope.activeTab.hasOwnProperty(key))
                    $scope.activeTab[key] = false;
            }
            $scope.activeTab[action] = true;
            $scope.availableIcons = iconService.GetTabContent(action);
        };

        $scope.allAvaliableIcons = iconService.GetAvailableIcons();

        $scope.availableIcons = iconService.GetTabContent($scope.activeTab.objects ? "objects" : $scope.activeTab.symbols ? "symbols" : $scope.activeTab.activities ? "activities" : $scope.activeTab.alphanumeric ? "alphanumeric" : []);


        $scope.iconMap = iconService.GetIconMap();
        $scope.getIcon = iconService.getIcon;

        $scope.ok = function (selectedIcon) {
            $modalInstance.close(selectedIcon);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss();
        };

    }
})();
