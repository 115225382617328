(function () {

    var app = angular.module('Plania');

    app.directive('mapClusterPopup', function () {
        return {
            restrict: 'E',
			controller: ['$scope', 'ngTableParams', '$filter', 'TranslationService', '$state', "$window", controller],
            templateUrl: 'app/map/views/mapClusterPopupDirective.html',
            link: function (scope, elements, attrs) {
            }
        };
    });

	function controller($scope, ngTableParams, $filter, translationService, $state, $window) {

        switch ($scope.selectedTheme) {
            case "basicData":
                $scope.title = translationService.translate('web-map-toolbar-selectTheme-Basicdata', 'Grunndata');
                break;
            case "workOrder":
                $scope.title = translationService.translate('web-map-toolbar-selectTheme-workOrder', 'Arbeidsordre');
                break;
            case "request":
                $scope.title = translationService.translate('web-map-toolbar-selectTheme-request', 'Meldinger');
                break;
            default:
                $scope.title = "Punkter";
        }

        $scope.mapClusterTableSmall = new ngTableParams({
            page: 1,
            count: 10
        },
            {
                total: $scope.clustermarkers.length,
                getData: function ($defer, params) {
                    var data = params.sorting() ? $filter('orderBy')($scope.clustermarkers, params.orderBy()) : $scope.clustermarkers;
                    data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());
                    $defer.resolve(data);
                }
			});


		var getMarkerStateName = function (mark) {
			var entityStateName = mark.entity.toLowerCase();
			if (entityStateName === "workorder")
				entityStateName = "workOrder";
			return entityStateName + ".edit";
		};

		$scope.getHref = function (mark) {
			return $state.href(getMarkerStateName(mark), { guid: mark.entityGuid });
		};

		$scope.navigate = function (event, mark) {
			// Open in new tab if ctrl is pressed or middle mouse button is clicked (event.which = 2)
			if (event.ctrlKey || event.which === 2) {
				event.preventDefault();
				var href = $scope.getHref(mark);
				$window.open(href, '_blank', "noopener,noreferrer");
			} else {
				$state.go(getMarkerStateName(mark), { guid: mark.entityGuid });
			}
		};
    }
})();
